import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AddCard from './Pages/AddCard/AddCard';
import PlaidRedirect from './Pages/PlaidRediect/PlaidRedirect';
import CardSuccess from './Pages/CardSuccess/CardSuccess';
import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import TransactionLogs from './Pages/TransactionLogs/TransactionLogs';
import UserLogs from './Pages/UserLogs/UserLogs';
import Compliance from './Pages/Compliance/Compliance';

export default (
    <Routes>
        <Route path="/addcard" element={<AddCard />} />
        <Route path="/plaid" element={<PlaidRedirect />} />
        <Route path="/cardsuccess" element={<CardSuccess />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/transactionlogs" element={<TransactionLogs />} />
        <Route path="/useractions" element={<UserLogs />} />
        <Route path="/compliance" element={<Compliance />} />
        <Route path="/" element={<Login />} />
    </Routes>
);