import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import './DataGrid.css';
import { DataGridProps, DataGridState } from './Interface';
import DownloadCsv from './Plugins/DownloadCsv';
import Search from './Plugins/Search/Search';

interface IDataRow {
    [column: string]: string | number | Date | any;
}

class DataGrid<D extends IDataRow, C> extends React.Component<DataGridProps<D>, DataGridState> {

    constructor(props_: DataGridProps<D>) {
        super(props_);

        this.state = {
            isRowExpanded: false
        }
    }

    public render() {
        return (
            <>
                <div className='dt-table-header-container'>
                    {
                        this.props.title && <h1>{this.props.title}</h1>
                    }
                    <div className='dt-table-header'>
                        {
                            this.props.search && <Search
                                onSearch={(searchData: string) => {
                                    if (this.props.onSearch) {
                                        this.props.onSearch(searchData);
                                    }
                                }}
                                searchPlaceholder={this.props.search}
                            />
                        }
                        {
                            this.props.exportCsv && <DownloadCsv data={this.props.data} columns={this.props.columns} />
                        }
                    </div>
                </div>

                <DataTable
                    dense={this.props.dense}
                    className='passport-datagrid'
                    fixedHeader
                    columns={this.props.columns}
                    pagination={this.props.pagination}
                    highlightOnHover
                    pointerOnHover
                    expandOnRowClicked
                    expandableRows={this.props.expandableRows}
                    expandableRowsComponent={
                        this.props.expandedComponent
                    }
                    onRowExpandToggled={(expanded: boolean) => {
                        this.setState({
                            isRowExpanded: expanded
                        })
                        console.log(expanded);
                    }}
                    paginationServer
                    paginationTotalRows={this.props.paginationTotalRows}
                    onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    onChangePage={this.props.onChangePage}
                    data={this.props.data} />
            </>
        )
    }
}

export default DataGrid;